.fab {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 4px 4px 10px #0000001c;
  transition: all 300ms ease-in-out;
  border: 1px solid #0000002f;

  position: fixed;
  bottom: 2rem;
  right: 1rem;
}

.fab:hover {
  transform: scale(1.05);
}

.fab svg {
  height: 20px;
  width: 20px;
}

:global(.secondary-bg) .fab { 
  background-color: var(--neutral);
  border: 1px solid var(--onNeutral);
  color: var(--onNeutral);
}

:global(.secondary-bg) .fab svg path {
  fill: var(--onNeutral)
}

:global(.neutral-bg) .fab { 
  background-color: var(--secondary); 
  border: 1px solid var(--onNeutral);
  color: var(--onSecondaryLow);
}

:global(.neutral-bg) .fab svg path {
  fill: var(--onSecondaryLow)
}

:global(.react-aria-Heading) ~ p {
  margin: .5em;
}

.fabPressed {
  box-shadow: 0px 0px 4px #00000000;
}
