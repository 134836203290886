.errorFallbackContainer {
  max-width: 400px;
  width: 100%;
  margin: auto 0;
  padding: 2rem;
  display: grid;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.error {
  background-color: var(--dangerSurface);
  padding: 2rem;
  border-radius: 1rem;
}

.error h2, .error p { color: var(--danger) }