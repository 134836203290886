.onPageErrorSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.onPageErrorSection h1, .onPageErrorSection p  {
  margin: 0 0 2rem 0;
}