.forceActivationSection h1 svg {
  height: 35px;
  width: 35px;
}

.forceActivationSection path {
  fill: var(--onSecondaryHigh);
}

.forceActivationSection h1 {
  margin-bottom: .5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.forceActivationSection h1 > * {
  margin-bottom: .5rem;
}

.forceActivationSection h2 {
  color: var(--onSecondaryHigh);
  margin-top: 0;
}

.forceActivationSection strong {
  color: var(--onSecondaryHigh);
}

.forceActivationSection .buttonContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.forceActivationSection .buttonContainer > * {
  margin-bottom: 1rem;
} 