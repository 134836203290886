.react-aria-ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--visual-viewport-height);
  background: rgba(0 0 0 / .5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.react-aria-ModalOverlay[data-entering] {
  animation: modal-fade 200ms;
}

.react-aria-ModalOverlay[data-exiting] {
  animation: modal-fade 150ms reverse ease-in;
}

.react-aria-Modal {
  --modal-padding: 1.5rem;

  background-color: var(--secondary);
  color: var(--onSecondaryLow);
  box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
  border-radius: 16px;
  outline: none;
  position: relative;
  width: 100%;
  margin: 1.25rem;
  max-width: 400px;
}

.react-aria-Modal &[data-entering] {
  animation: modal-zoom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.react-aria-Modal section:focus-visible {
  outline: none;
}

.react-aria-Modal .modal-header {
  display: flex;
  padding: var(--modal-padding);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255 255 255 / 0.1);
}

.react-aria-Modal .modal-header > *:first-child {
  margin-right: 3rem;
}

.react-aria-Modal .modal-header h2 { margin: 0 }

.react-aria-Modal .modal-header + .modal-body {
  text-align: left;
  padding: var(--modal-padding);
}

.react-aria-Modal .modal-error-header, .modal-caution-header {
  padding: 2rem var(--modal-padding) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-aria-Modal .modal-error-header h2 { margin: 1rem 0 0 0 }

.modal-caution-header h2 { margin: .5rem 0 0 0 }

.react-aria-Modal .modal-error-header .modal-close, .react-aria-Modal .modal-caution-header .modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.react-aria-Modal .modal-error-header + .modal-body {
  text-align: center;
  padding: 0 var(--modal-padding) 1rem;
}

.react-aria-Modal .modal-caution-header + .modal-body {
  text-align: center;
  padding: 1rem var(--modal-padding);
}

.react-aria-Modal .modal-footer {
  display: flex;
  padding: var(--modal-padding);
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  border-top: 1px solid rgba(255 255 255 / 0.1);
}

.react-aria-Modal .modal-footer .primary-button {
  flex: 1;
}

.react-aria-Modal .modal-footer button {
  padding: 1rem;
}

.alert-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--dangerSurface);
  border-radius: 50%;
}

.alert-icon path {
  fill: var(--danger);
}

.caution-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: none;
  border-radius: 50%;
}

.caution-icon svg {
  width: 35px;
  height: 35px;
}

.caution-icon path {
  fill: var(--primary);
}

.modal-close {
  height: 2rem;
  width: 2rem;
  transition: all 300ms ease-in-out;
  color: var(--neutral);
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.modal-close:hover {
  transform: scale(1.05);
}

.modal-close svg {
  height: 24px;
  width: 24px;
}

.modal-close svg path { fill: var(--onSecondaryLow) }

@keyframes modal-fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modal-zoom {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}